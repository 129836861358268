import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MatOptionModule } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { format, getHours, parseISO, setHours, setMinutes } from 'date-fns';
import { SharedModule } from '../../modules/shared.module';
import { APP_DATE_FORMATS, AppDateAdapter } from '../../utils/format-datepicker';

@Component({
	selector: 'addiction-date-picker',
	templateUrl: './date-picker.component.html',
	providers: [
		{ provide: DateAdapter, useClass: AppDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: DatePickerComponent,
		},
	],
	standalone: true,
	imports: [CommonModule, SharedModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatSelectModule, MatOptionModule],
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {
	private readonly quarterHours = ['00', '15', '30', '45'];

	timeOptions: string[] = [];
	disabled: boolean = false;

	formGroup = new FormGroup({
		date: new FormControl<Date | null>(null),
		time: new FormControl<string | null>(null),
	});

	value: Date | undefined;

	@ViewChild('timeInput') public timeInput: MatSelect | undefined;
	@Input() defaultDate?: Date = new Date();
	@Input() showTime: boolean = false;
	@Input() showDate: boolean = true;
	@Input() dateLabel: string | undefined;
	@Input() timeLabel: string | undefined;
	/** initial date to set if not in inside a form */
	@Input() initialDate?: string | null;
	@Input() minDate: Date | undefined;
	@Input() maxDate: Date | undefined;
	@Input() readonly: boolean = false;

	@Output() dateChanged = new EventEmitter<string>();

	constructor() {}

	ngOnInit(): void {
		// console.log('initialDate', this.initialDate);
		for (let i = 0; i < 24; i++) {
			for (const quarterHour of this.quarterHours) {
				this.timeOptions.push(i + ':' + quarterHour);
			}
			// console.log('time', this.timeOptions);
		}

		if (this.defaultDate && !this.formGroup.value.date) {
			// init date picker form controls from date
			this.formGroup.patchValue({
				date: this.defaultDate,
				time: this.getTimeFromIsoFormat(this.defaultDate),
			});
		}
	}

	registerOnChange(fn: (val: Date | undefined) => void): void {
		this.formGroup.valueChanges.subscribe((v) => {
			const date = v.date instanceof Date ? v.date : this.defaultDate;
			if (v.time) {
				const [_, hours, minutes] = !Array.isArray(v.time) ? v.time.match(/(\d+)\:(\d+)/) ?? [] : v.time;
				if (hours && minutes && date) {
					date.setHours(+hours, +minutes);
				}
			}
			this.value = date;
			fn(date ?? undefined);
		});
	}

	registerOnTouched(fn: () => void): void {
		this._registeredOnTouched = fn;
	}

	setDisabledState(disabled: boolean) {
		if (disabled) this.formGroup.disable({ emitEvent: false });
		else this.formGroup.enable({ emitEvent: false });
	}

	private _registeredOnChange = (_value: Date | undefined) => {
		return;
	};

	private _registeredOnTouched = () => {
		return;
	};

	writeValue(obj: Date | null): void {
		if (obj instanceof Date) {
			this.formGroup.patchValue({
				date: obj,
				time: this.getTimeFromIsoFormat(obj),
			});
		} else {
      this.formGroup.reset()
    }
	}

	updateDateTime(event: MatSelectChange | MatDatepickerInputEvent<unknown, unknown>, type: string) {
		// console.log('event', event);
		if (event.value) {
			let date;
			let time;
			if (type === 'date') {
				date = new Date(event.value);
			}

			if (type === 'time') {
				time = event.value.match(/(\d+)\:(\d+)/);
			}
			if (!date && this.defaultDate) {
				date = this.getDateFromIsoFormat(this.defaultDate);
			}
			if (!time && this.defaultDate && this.showTime && this.timeInput) {
				time = this.timeInput['value']?.match(/(\d+)\:(\d+)/);
			}
			if (date) {
				// emit dateChanged event only if data is also set(date changed/selected or defaultDate results set)
				if (time?.length > 0) {
					if (time[0].includes(':')) {
						date = setHours(date, +time[1]);
						date = setMinutes(date, +time[2]);
					} else {
						date = setHours(date, +time[0]);
						date = setMinutes(date, +time[1]);
					}
				}
				this.dateChanged.emit(date.toISOString());
			}
			// this.formGroup.patchValue({
			// 	date: date,
			// 	time: time,
			// });
		} else {
			this.formGroup.reset();
			this.dateChanged.emit('');
		}
	}

	getDateFromIsoFormat(isoDate: Date): Date {
		const newDate = new Date(isoDate);
		const stringDate = newDate.toISOString();
		return parseISO(stringDate);
	}

	getTimeFromIsoFormat(isoDate: Date | null): string | null {
		if (!isoDate) return null;
		const defaultDate = this.getDateFromIsoFormat(isoDate);
		const calcHours = getHours(defaultDate);
		const calcMinutes = format(defaultDate, 'mm');
		return `${calcHours}:${calcMinutes}`;
	}
}
